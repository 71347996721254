// Height for Mobile Menu Bar
//////////////////////////////

$menu-bar: 50px;

.page-navi {
	
	@include breakpoint(large) {
		position:absolute;
		left:0;
		width:100%;
		top:rem(106px);
		background:$light;
		transition:0.3s linear padding;
		padding:rem(28px) 0;
	    border-bottom:1px solid $border;
		.index &{
			top:rem(518px);
		}
		.stuck &{
			position:fixed;
			top:rem(86px);	
			z-index:101;
			padding:rem(28px) 0 rem(20px) 0;
			.index &{
				top:rem(86px);
				padding:rem(20px) 0;
			}
		}
		
	}
	// Off Canvas Styles
	//////////////////////////////
	
	@include breakpoint(large, max) {
		background:$light;
		left: 0;
		overflow-y: auto;
		position: fixed;
		top: rem($menu-bar);
		transform: translateX(-100%);
		transition: .6s;
		width: map-get($breakpoints, tiny) * .8;
		z-index: 895;
	    bottom: 0;
	    max-width: 100%;
	}
	nav{
		width:100%;
		@include breakpoint(large) {
			padding:0 rem($base-gap);
		}
	}
	.navi-main {
		@include breakpoint(large) {
			display:flex;
			justify-content: space-between;
		}

		li {
			&.impressum, &.datenschutzerklaerung, &.newsletter, &.facebook-fanpage{
				@include breakpoint(large) {
					display:none;
				}
			}
			a{
				text-decoration:none;
				font-size:rem(18px);
				line-height:rem(30px);
				color:$dark;
				font-weight:400;
				padding:rem(14px) rem(18px);
				display:block;
				border-bottom:1px solid $border;
				@include breakpoint(large) {
					padding:rem(0px);
					border:none;
				}
				&:hover, &:focus, &:active, &.active{
					color:$primary;
				}
			}
		}

	}
}
.navi-add{
	text-align:center;
	li{
		display:inline-block;
		margin:0 rem(21px);
		&:first-child{
			margin-left:0;
		}
		
		a{
			display:block;
			text-decoration:none;
			color:$light;
			font-size:rem(18px);
			line-height:rem(30px);
			position:relative;
			&:before{
				position:absolute;
				left:0;
				bottom:0;
				width:0;
				opacity:0;
				visibility:hidden;
				content:'';
				background:$light;
				height:1px;
				transition:0.3s linear opacity, 0.3s linear visibility, 0.3s linear width;
			}
			&:hover, &:focus, &:active{
				&:before{
					opacity:1;
					visibility:visible;
					width:100%;
				}
			}
		}
		&.facebook-fanpage {
			a{
				padding-left:rem(40px);
				&:before{
					display:none;
				}
				&:after{
					background:image-url("layout/fb.png") no-repeat center center;
					position:absolute;
					left:0;
					top:0;
					width:rem(16px);
					height:rem(29px);
					content:'';
					transition:0.3s linear opacity;
				}
				&:hover, &:focus, &:active{
					&:after{
						opacity:0.5;
					}
				}
			}
		}
	}
}
// Mobile Menu Bar
//////////////////////////////

.mobile-bar {
	align-items: center;
	background: $secondary;
	display: flex;
	height: rem($menu-bar);
	justify-content: stretch;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 894;
	color:$light;

	li {
		display: flex;
		flex-grow: 1;
		padding: 0 rem($base-gap);

		&:last-child {
            justify-content: flex-end;
        }

        label[for="navi-toggled"] {
			@extend .fa-bars;
			flex-direction: row-reverse;
			font-size: rem(16px);
			cursor:pointer;

			&:before {
				@extend .fa-default;
				margin-right: rem(10px);
			}

			&:after {
				background: rgba($dark,0.8);
				bottom: 0;
				content: '';
				left: 0;
				opacity: 0;
				pointer-events: none;
				position: fixed;
				top: rem($menu-bar);
				transition: opacity .6s, visibility .6s;
				visibility: hidden;
				width: 100%;
				z-index: -1;
			}
		}
	}

	@include breakpoint(large) {
		display: none;
	}
}

#navi-toggled {
	display: none;

	&:checked {

		~ * label[for="navi-toggled"] {
			@extend .fa-close;

			&:after {
				opacity: 1;
				pointer-events: auto;
				visibility: visible;
			}
		}

		~ * .page-navi, ~ .page-navi {
			transform: translateX(0);
		}
	}
}