// Form styles
form.default {
    overflow: hidden;
    width: 100%;

    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: #777777;
    }

    ::-moz-placeholder { /* Firefox 19+ */
        color:#777777;
    }

    :-ms-input-placeholder { /* IE 10+ */
        color: #777777;
    }

    :-moz-placeholder { /* Firefox 18- */
        color: #777777;
    }

    fieldset {
        border:none;
        margin: 0 0 3.5rem 0;
		.contact-form &{
			margin:0;
		}
    }

    label, input, textarea, select, button, [type="submit"] {
        min-height: 3.5rem;
    }
	legend{
		display:none;
	}

    label {
        color: inherit;
        display: none;
        padding: 1rem 0 0.6rem;
        position: relative;
		color: $light;
		.newsletter &{
			color:$dark;
		}
		&[for="Dateiupload"]{
			font-size: 1rem;
			text-overflow: ellipsis;
			white-space: nowrap;
			cursor: pointer;
			display: inline-block;
			background:$light;
			color: #777777;
			line-height: rem($base-line-height);
        	margin: 0 0 rem(16px) 0;
        	padding: rem(19px) rem(14px) rem(17px) rem(29px);
			min-height: 0;
			font-weight: 300;
			font-style:italic;
			width: 100%;
		}
		&[for="Rueckruf"], &[for="Datenschutz"]{
			.kontakt &{
				color:$dark;
			}
		}
        small {
            color: $alert;
            display: inline;
			.contact-form &{
				color:$light;
			}
        }
		&.radio{
			  display: inline-block;
			  margin-right: 20px;
		}
		&[for="Zustimmung"] {
			display: inline-block;
  		    width: 80%;
		}
        &[for="Datenschutz"] {
            @extend .margin-bottom;
			display:block;
            float: left;
			font-style:italic;
			transition:0.3s linear padding, 0.3s linear border;
			border:1px solid transparent;
            width: calc(100% - #{rem(30px)});
			.contact-form &{
				@include breakpoint(medium) {
					 float: right;
				}
			}
				

			a{
				.contact-form &{
					color:$light;
					&:hover, &:focus, &:active{
						color:#5eb2ef;
					}
				}
			}
			.error &{
				color:$alert;
				display:inline;
			}
			
        }
		&[for="Rueckruf"] {
			display:block;
		}
		&.error{
			display:none !important;
		}
		.newsletter &{
			display:inline-block;
		}
    }
	
    [type="text"], [type="tel"], [type="email"], [type="file"], input, textarea, select {
        background: $light;
        border: 1px $border solid;
        color: #777777;
        display: block;
        font-family: $main-font;
        font-size: 1rem;
        line-height: rem($base-line-height);
        margin: 0 0 .5rem 0;
        padding: .8rem;
        position: relative;
        resize: none;
        transition: border .3s;
        width: 100%;
		font-weight: 300;
		font-style:italic;

        &:focus {
            background: darken($light, 2%);
            outline: none;
        }
		.contact-form &{
			border:1px solid $light;
			margin-bottom:rem(16px);
		    padding: rem(19px) rem(14px) rem(17px) rem(29px);
			transition:0.3s linear border;
		}
		&.error {
			border:1px solid $alert;
		}
		
	
    }
	[type="file"]{
		display:none;
	}
    textarea { /* fake line-height */
		&#Nachricht, &#Kenntnisse{
			min-height:rem(149px);
		}
    }

    [type="checkbox"]{
        display: inline-block;
        float: right;
        margin: 0 0 .5rem rem(15px) !important;
        padding: 0 !important;
		border:none !important;
        width: rem(15px);
        height: rem(15px);
		background:none;
		.contact-form &{
			@include breakpoint(medium) {
				float: left;
				margin: 0 rem(15px) .5rem 0 !important;
			}
			
		}
        body.iexplore & {
            border: none;
            
            &:focus {
                background: none;
            }
        }
		&.error{
			~ label[for="Datenschutz"]{
				border: 1px solid $alert;
   				padding-left: 10px;
			}
		}
		
    }

    [type="radio"] {
        clear: none;
        float: left;
        width: rem(15px);
		height:rem(15px);
        margin: .4rem .8rem 0 0;
        min-height: auto;
		display:inline-block;
        padding: 0 !important;
		border:none !important;
		
    }

    button, [type="submit"] {
        background: $secondary;
        border: none;
        color: $light;
        cursor: pointer;
        font-family: $main-font;
        font-size: 1rem;
        margin: 0 0 1.75rem;
        padding: .8rem $base-gap;
        width: 100%;
        transition: .3s linear background;

        &:hover, &:focus {
            background: $primary;
        }
		.contact-form &{
			padding: .8rem rem(100px);
			@include breakpoint(medium) {
				float:right;
				width:auto;
			}
			
		}
    }

    .errorContainer {
        position: relative;
    }
	.hidden-text{
		display:none;
	}
	.form-hr{
		background:#649dc5;
		margin:rem(40px) 0 rem(44px) 0;
	}
}

/*=VALIDATION */

@keyframes error {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-webkit-keyframes error {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.specialfield {
    display: none !important;
    visibility: hidden !important;
}

#newsletter-info {
    @extend input;
    font-weight: bold;
}

img[name=vimg] + input[name=imgverify] {
    float: right;
}