// if you want to use more file types, write: $type: woff ttf woff2 (no comma separation!)

// @include font-face(
// 	$name: "icomoon",
// 	$file: "icomoon-webfont",
// 	$weight: 400,
// 	$style: normal,
// 	$type: woff
// );

@include font-face(
	$name: "FontAwesome",
	$file: "fontawesome-webfont",
	$weight: 400,
	$style: normal,
	$type: woff
);

@include font-face(
	$name: "Roboto",
	$file: "roboto-v27-latin-300",
	$weight: 300,
	$style: normal,
	$type: woff woff2 svg eot ttf
);

@include font-face(
	$name: "Roboto",
	$file: "roboto-v27-latin-300italic",
	$weight: 300,
	$style: italic,
	$type: woff woff2 svg eot ttf
);
@include font-face(
	$name: "Roboto",
	$file: "roboto-v27-latin-regular",
	$weight: 400,
	$style: normal,
	$type: woff woff2 svg eot ttf
);

@include font-face(
	$name: "Roboto",
	$file: "roboto-v27-latin-700",
	$weight: 700,
	$style: normal,
	$type: woff woff2 svg eot ttf
);