// PAGE WRAP
//////////////////////////////

.page-wrap {
	padding-top: rem($menu-bar);
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	flex: 1;
	
	@include breakpoint(large) {
		padding-top: 0;
		
	}
}
.page-wrap-outer {
		display: flex;
		flex-direction: column; 
}
// HEADER
//////////////////////////////

.header {
	background: $light;
	position: relative;
	width: 100%;
	@include breakpoint(large) {
		padding-top:rem(134px);
	}

	.branding {
		display: inline-block;
	}
	.btns-box{
		text-align:right;
	}
	.phone-num{
		font-size:rem(20px);
		line-height:rem(30px);
		text-decoration:none;
		padding:rem(13px) rem(35px) rem(13px) rem(75px);
		background:$primary;
		color:$light;
		display: none;
		position:relative;
		&:before{
			content:'';
			position:absolute;
			left:rem(40px);
			top:50%;
			transform:translateY(-50%);
			background:image-url("layout/phone.png") no-repeat center center;
			width:rem(21px);
			height:rem(23px);
			display:block;
		}
		@include breakpoint(medium) {
			display: inline-block;
		}
		a{
			text-decoration:none;
		}
		&.standart-num{
			
			@include breakpoint(medium) {
				display:none;
			}
			@include breakpoint(large) {
				display: inline-block;
			}
		}
		&.mobile-num{
			background:$secondary;
			margin-left:rem(32px);
			&:before{
				background:image-url("layout/mobile.png") no-repeat center center;
				width:rem(14px);
				height:rem(27px);
				background-size:contain;
				.iexplore &{
					width:rem(20px);
				}
			}
		}
	}
	.top-bar{
		padding:rem(20px) 0;
		text-align:center;
		@include breakpoint(large) {
			padding:rem(38px) 0 rem(28px) 0;
			position:fixed;
			top:0;
			left:0;
			width:100%;
			background: $light;
			z-index:101;
			transition:0.3s linear padding;
			text-align:left;
		}
		.stuck &{
			@include breakpoint(large) {
				padding:rem(18px) 0 rem(0px) 0;
			}
		}
	}
	.header-cont{
		background:image-url("layout/header-bg-small.jpg") no-repeat center center;
		background-size:cover;
		padding:rem(60px) 0;
		@include breakpoint(small) {
			background:image-url("layout/header-bg.jpg") no-repeat center center;

		}
		@include breakpoint(medium) {
			padding:rem(111px) 0;
			min-height:rem(384px);
		}
		.header-text{
			.nojs &{
				display:none;
				&:first-child{
					display:block;
				}
			}
		}
		.header-title{
			color:$light;
			font-size:rem(20px);
			line-height:rem(28px);
			display:block;
			font-weight:300;
			margin-bottom:rem(48px);
			@include breakpoint(medium) {
				font-size:rem(30px);
			}
			span{
				display:block;
				color:#aadbff;
				font-size:rem(18px);
				line-height:rem(28px);
				padding-top:2px;
				@include breakpoint(medium) {
						font-size:rem(20px);
						line-height:rem(36px);
				}
		
					a{
						color:#01406f;
					}
						
			}
		}
		.btn{
			padding: rem(18px) rem(48px) rem(17px) rem(48px);
			min-width:0;
		}
		
	}
	.slick-slider{
		padding-left:rem(18px);
		padding-right:rem(18px);
	}
	
}

// MAIN CONTENT
//////////////////////////////

main {
	display: block;
	font-weight:300;
	padding:rem(60px) 0;
	flex: 1 0 auto;
	@include breakpoint(large) {
		padding:rem(137px) 0 rem(60px) 0;
		
	}
	ul, dl {
		@extend .styled-list;
	}

	.google-maps {
		@extend .video-container;

		@include breakpoint(medium) {
			height: rem(400px);
		}
	}
	.index &{
		@include breakpoint(giant) {
			background:image-url("layout/bottom-bg.jpg") no-repeat right bottom;
		}
	}
}
.teasers{
	background:$primary;
	padding:rem(60px) 0 rem(40px) 0;
	@include breakpoint(medium) {
		padding:rem(94px) 0;
	}
	.teaser{
		color:$light;
		text-decoration:none;
		display:inline-block;
		text-align:center;
		.teaser-title{
			display:block;
			color:#d4edff;
			font-size:rem(24px);
			line-height:rem(30px);
			margin-bottom:rem(30px);
		}
		p{
			@include breakpoint(medium) {
				margin-bottom:0;
			}
		}
		.btn-block{
			height:rem(130px);
			position:relative;
			margin-bottom:rem(50px);
			overflow:hidden;
			> span.btn{
				position:absolute;
				left:50%;
				top:50%;
				transform:translate(-50%,-100%);
				opacity:0;
			    visibility:hidden;
				@include breakpoint(medium) {
					min-width:rem(200px);
				}
				@include breakpoint(large) {
					min-width:rem(284px);
				}
				
			}
		}
		&:hover, &:focus, &:active{
			.btn-block{
				> span.btn{
					opacity:1;
					visibility:visible;
					transform:translate(-50%,-50%);
				}
			}
		}
			&.teaser-01{
				.btn-block{
					background-size:cover;
					&.lazyLoaded {
						background:image-url("layout/teaser-01.jpg") no-repeat center center;
					}
				}
			}
			&.teaser-02{
				.btn-block{
					background-size:cover;
					&.lazyLoaded {
						background:image-url("layout/teaser-02.jpg") no-repeat center center;
					}
				}
			}
			&.teaser-03{
				.btn-block{
					background-size:cover;
					&.lazyLoaded {
						background:image-url("layout/teaser-03.jpg") no-repeat center center;
					}
				}
			}
					
		
	}
	
}
.cont-01{
	padding:rem(60px) 0;
	@include breakpoint(medium) {
		padding:rem(84px) 0 rem(51px) 0;
	}
}
.contact-form{
	padding:rem(60px) 0;
	color:$light;
	&.lazyLoaded {
		background:image-url("layout/contact-form-small.jpg") no-repeat top center #17649c;
	
	}
	@include breakpoint(small) {
		&.lazyLoaded {
			background:image-url("layout/contact-form.jpg") no-repeat top center #17649c;
		}

	}
	@include breakpoint(medium) {
		padding:rem(107px) 0 rem(91px) 0;
	}
	.jobs-fuer-geruestbauer-php &{
		margin-top:rem(100px);
	}
}
.index-title{
	display:block; 
	font-size:rem(24px);
	line-height:rem(40px);
	margin-bottom:rem(30px);
	font-weight:700;
	@include breakpoint(medium) {
		font-size:rem(40px);
		line-height:rem(55px);
		margin-bottom:rem(30px);
	}
	@include breakpoint(large) {
		font-size:rem(60px);
		line-height:rem(55px);
		margin-bottom:rem(44px);
	}
	span{
		@include breakpoint(large) {
			display:block;
		}
	}
}
.cont-03{
	padding:rem(60px) 0;
	@include breakpoint(medium) {
		padding:rem(104px) 0 rem(120px) 0;
	}
}
.cont-04{
	background-size:cover;
	padding:rem(60px) rem($base-gap);
	color:$light;
	text-align:center;
	margin:0 rem(-$base-gap);
	margin-bottom:rem(54px);
	&.lazyLoaded {
		background:image-url("layout/cont-04.jpg") no-repeat center center;
	}
	@include breakpoint(medium) {
		padding:rem(110px) rem(80px);
		margin:0;
		margin-bottom:rem(54px);
	}
	@include breakpoint(large) {
		padding:rem(108px) rem(204px) em(82px) rem(204px);
	}
	p{
		@include breakpoint(medium) {
			font-size:rem(30px);
			line-height:rem(36px);
			margin-bottom:rem(44px);
		}
	}
	.btn{
		font-weight:400;
		
	}
	.index-title{
		@include breakpoint(medium) {
			margin-bottom:rem(61px);
		}
	}
}
.cont-05{
	position:relative;
	padding:rem(58px) 0 rem(52px) 0;
	.map-box{
		text-align:center;
		position:relative;
		&:before{
			background:$border;
			width:1px;
			height:auto;
			content:'';
			display:block;
			transition:0.3s linear transform;
			position:absolute;
			right:0;
			top:rem(-60px);
			bottom:rem(-60px);
			display:none;
			@include breakpoint(medium) {
				display:block;
			}
			
		}
	}
	.map{
		display:inline-block;
		position:relative;
		&:before{
			background:image-url("layout/map.png") no-repeat center center;
			width:rem(86px);
			height:rem(104px);
			content:'';
			display:inline-block;
			transition:0.3s linear transform;
		}
		&:after{
			background:$primary;
			width:rem(20px);
			height:rem(20px);
			content:'';
			display:block;
			position:absolute;
			left:50%;
			top:30px;
			transform:translateX(-50%) scale(0,0);
			transition:0.3s linear transform;
			border-radius:100%;
		}
		&:hover, &:focus, &:active{
			&:after{
				transform:translateX(-50%) scale(1,1);
			}
			
		}
	}
	p{
		
		font-size:rem(24px);
		line-height:rem(36px);
		font-weight:300;
		text-align:center;
		padding-top:rem(10px);
		margin-bottom:0;
		@include breakpoint(medium) {
			padding-left:rem(30px);
			text-align:left;
			padding-top:rem(0px);
		}
		@include breakpoint(large) {
			padding-left:rem(63px);
		}
		span{
			@include breakpoint(medium) {
				display:block;
			}
		}
		strong{
			font-weight:700;
		}
	}
}
.image{
    margin-bottom:rem(20px);
	@include breakpoint(small) {
		float: left;
    	margin: 0 rem(20px) rem(20px) 0;
	}
}
.loop{
	 margin-bottom:rem(30px);
}
// FOOTER
//////////////////////////////

.footer {
	display: block;
	.footer-cont{
		background:$secondary;
		color:$light;
		padding:rem(45px) 0;
		span.customer{
			font-weight:700;
			text-align:left;
			position:relative;
			display:block;
			@include breakpoint(medium) {
				padding-right:rem(18px);
				display:inline-block;
				margin-right:rem(18px);
			}
			@include breakpoint(large) {
				display:block;
				float:none;
				text-align:center;
				margin-right:rem(0px);
			}
			@include breakpoint(giant) {
				float:left;
				text-align:left;
				padding-right:rem(18px);
			}
			@include breakpoint(giant2) {
				padding-right:rem(25px);
			}
			&:before{
				  position:absolute;
				  right:rem(0px);
				  top:0;
				  width:1px;
				  height:100%;
				  opacity:1;
				  visibility:visible;
				  content:'';
				  background:#557f9e;
				  transition:0.3s linear opacity, 0.3s linear visibility, 0.3s linear width;
				  display:none;
				  @include breakpoint(medium) {
					  display:block;
				  }
				  @include breakpoint(large) {
					  display:none;
				  }
				  @include breakpoint(giant) {
					  display:block;
				  }
			}
		}
		address{
			display:inline-block;
			font-style:normal;
		}
		.col{
			@include breakpoint(large) {
				text-align:center;
			}
			@include breakpoint(giant) {
				text-align:right;
			}
		}
		dl{
			display:inline-block;
			margin:0;
			dt, dd{
				display:inline-block;
				font-size:rem(16px);
				@include breakpoint(tiny) {
					font-size:rem(18px);
				}
				a{
					color:$light;
					text-decoration:none;
					position:relative;
					display:inline-block;
						&:before{
							position:absolute;
							left:0;
							bottom:0;
							width:100%;
							opacity:1;
							visibility:visible;
							content:'';
							background:$light;
							height:1px;
							transition:0.3s linear opacity, 0.3s linear visibility, 0.3s linear width;
						}
						&:hover, &:focus, &:active{
							&:before{
								opacity:0;
								visibility:hidden;
								width:0;
							}
						}
				}
			}
			dd{
				width:78%;
				float:right;
				@include breakpoint(medium) {
					width:auto;
					float:none;
				}
			}
			dt{
				font-weight:700;
				width:22%;
				float:left;
				@include breakpoint(medium) {
					width:auto;
					float:none;
				}
				
			}
		}
	}
	.bottom-line{
		background:$primary;
		padding:rem(22px) 0;
		display:none;
		@include breakpoint(medium) {
			display:block;
		}
	}
}

body:not(.javascript) * { transition: none!important }


.privacyControlBadge {
	bottom: 5rem!important;
	&:hover {
		background:$primary;
		color: $light;
		
	}

}
